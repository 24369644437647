import componentInitializer from "@/frontend/utils/componentInitializer"
import { i18n } from "@/lib/vue/plugins"
import store from "@/store"
import Vue, { defineAsyncComponent } from "vue"

componentInitializer(() => {
  let el = document.getElementById("vue-home-page-staff-picks")
  let observer = new IntersectionObserver(([observedElement], observer) => {
    if (observedElement.isIntersecting) {
      const HomePageStaffPicks = defineAsyncComponent(() => import("@/pages/HomePage/StaffPicks.vue"))
      window.homePageStaffPicks = new Vue({
        el,
        store,
        i18n,
        render: (h) =>
          h(HomePageStaffPicks, {
            props: {
              isArtist: JSON.parse(el.dataset.isArtist || false),
              isAdmin: el.isAdmin === "true",
            },
          }),
      })
      observer.unobserve(observedElement.target)
    }
  })

  if (el) {
    observer.observe(el)
  }
})
